html, body {
  height: 100%;
  width: 100%;
}

.cdk-virtual-scroll-viewport {
  .cdk-virtual-scroll-content-wrapper {
    contain: initial;
  }
}

.d-hover-block,
.d-hover-flex,
.d-hover-grid,
.d-hover-inline,
.d-hover-inline-block,
.d-hover-inline-flex,
.d-hover-table,
.d-hover-table-cell,
.d-hover-table-row {
  display: none !important;
}
.hover-group:hover {
  .d-hover-block {
    display: block !important;
  }
  .d-hover-flex {
    display: flex !important;
  }
  .d-hover-grid {
    display: grid !important;
  }
  .d-hover-inline {
    display: inline !important;
  }
  .d-hover-inline-block {
    display: inline-block !important;
  }
  .d-hover-inline-flex {
    display: inline-flex !important;
  }
  .d-hover-table {
    display: table !important;
  }
  .d-hover-table-cell {
    display: table-cell !important;
  }
  .d-hover-table-row {
    display: table-row !important;
  }
}

.hide-unchanged {
  .unchanged-field,
  .unchanged-group {
    display: none;
  }
}

.hide-updatedBy {
  .alert-updatedBy {
    display: none;
  }
}

.form-check-input[type="checkbox"]:indeterminate {
  background: transparent;
  border-color: black;
  color: transparent;
}

.max-vw-100 {
  max-width: 100vw;
}

app-glossary-term-modal tbody {
  vertical-align: top;
  white-space: pre-wrap;
}

.modal-fullscreen {
  margin-top: 48px;
  max-height: calc(100vh - 48px);
}

.modal-content {
  max-height: calc(100vh - 28px - 28px);
}
.modal-body {
  overflow: auto;
}

typeahead-container.dropdown {
  z-index: 1100 !important;
}

.summary-details table {
  width: 100%;
}

.pdf-fonts {
  font-family: 'MarkWebPdf';
}

// Fix for custom repeat group - Misc Questions
.table .form-check-input { margin-left: -1.5rem; }
.table-misc-qs .mb-4 { margin-bottom: 0 !important; }

// hide Intercom launcher icon on mobile
@media (max-width: 575px) {
  .intercom-launcher, .intercom-launcher-frame {
    display: none !important;
  }
}
