@font-face {
  font-family: Copernicus;
  src: url(../fonts/Copernicus-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Copernicus;
  src: url(../fonts/Copernicus-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Copernicus;
  src: url(../fonts/Copernicus-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Copernicus;
  src: url(../fonts/Copernicus-SemiboldItalic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: Copernicus;
  src: url(../fonts/Copernicus-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Copernicus;
  src: url(../fonts/Copernicus-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: FFMark;
  src: url(../fonts/MarkWeb.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FFMark;
  src: url(../fonts/MarkWeb-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: FFMark;
  src: url(../fonts/MarkWeb-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: FFMark;
  src: url(../fonts/MarkWeb-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: FFMarkBook;
  src: url(../fonts/MarkWeb-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FFMarkBook;
  src: url(../fonts/MarkWeb-BookItalic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: FFMarkHeavy;
  src: url(../fonts/MarkWeb-Heavy.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FFMarkHeavy;
  src: url(../fonts/MarkWeb-HeavyItalic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: FFMarkLight;
  src: url(../fonts/MarkWeb-Light.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FFMarkLight;
  src: url(../fonts/MarkWeb-LightItalic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: FFMarkLight;
  src: url(../fonts/MarkWeb-Book.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: FFMarkMedium;
  src: url(../fonts/MarkWeb-Medium.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FFMarkMedium;
  src: url(../fonts/MarkWeb-MediumItalic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MarkWebPdf';
  src: url(../fonts/MarkWeb.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'MarkWebPdf';
  src: url(../fonts/MarkWeb-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'MarkWebPdf';
  src: url(../fonts/MarkWeb-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'MarkWebPdf';
  src: url(../fonts/MarkWeb-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
