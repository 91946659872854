/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/cooley.min.css";
@import "./assets/styles/fonts.scss";
@import "./assets/styles/custom.scss";
@import "./assets/styles/kendo-overrides.css";

.form-control,
.form-select {
  max-width: 640px;
}

.field-label p {
  margin: 1rem 0;
}

.hide-labels {
  .field-label {
    display: none;
  }
}

.hoverable-card:hover {
  background-color: #dfdfdf;
}

.hoverable-card:hover > * .appear-on-hover {
  display: block;
}

.hoverable-card > * .appear-on-hover {
  display: none;
}

.nav-tabs {
  .nav-link {
    border-width: 0 0 2px;
    height: 2rem;
  }
}

.navbar-brand span:first-of-type {
  display: block !important;
}

.navbar-brand span:last-of-type {
  display: none !important;
}
