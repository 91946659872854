.k-input {
  padding: 5px;
  border-radius: 0;
  border-color: #a8a8a8;
}

.k-chip,
.k-chip:hover {
  border-radius: 3px;
  background: #d1d5ff;
}

.k-input,
.k-chip-label,
.k-list-item {
  font-family: var(--bs-font-sans-serif);
  font-size: 14px;
}

.k-chip-label {
  color: #5046ff;
}

.k-list-item,
.k-list-item:hover,
.k-list-item:active,
.k-list-item:focus,
.k-list-optionlabel,
.k-list-optionlabel:hover,
.k-list-optionlabel:active,
.k-list-optionlabel:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.k-list-item:hover {
  border-color: transparent;
  background: #f4f4f4;
  color: #000000;
}

.k-list-item.k-selected,
.k-list-item.k-selected:hover {
  border-color: transparent;
  background: #e0e0e0;
  color: #000000;
}

.k-icon {
  color: #5046ff;
  width: 13px;
}
